export const primary = {
  100: '#FFF2EB',
  200: '#FFD4B2',
  300: '#FAB77D',
  400: '#F2A057',
  500: '#EA7100',
}

export const orange = {
  light: '#FFCA96',
  clay: '#943D15',
  dark: '#72370E',
  extra: '#ff7a00',
}

export const white = '#FFFFFF'
export const sand = '#FDF8EE'
export const graphite = '#3C3331'
export const graphiteOne = '#616161'
export const gray = '#EBEBEB'
export const secundaryBlue = '#66CCFB'
export const secundaryGreen = '#80E7BD'
export const secundaryPink = '#F8B6E6'
export const secundaryYellow = '#FBFF71'
export const warning = '#FFC700'
export const bluePetroleum = '#2E3445'
export const ceramics = '#D8D4C0'
